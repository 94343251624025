import '../css/styles.css';

if (process.env.NODE_ENV == 'production') {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', process.env.GA_TRACKING_ID);
}

const menuToggler = document.querySelector('.toggle-menu');
const menu = document.querySelector('.main-menu');

menuToggler.addEventListener('click', mobileMenu);

function mobileMenu() {
  menuToggler.classList.toggle('active');
  menu.classList.toggle('hidden');
}

const accordion = document.getElementsByClassName('accordion');

Array.prototype.forEach.call(accordion, function (element) {
  const controls = element.getElementsByTagName('button');

  Array.prototype.forEach.call(controls, function (btn) {
    btn.addEventListener(
      'click',
      function (e) {
        const contentId = e.currentTarget.getAttribute('aria-controls');
        const contentElement = document.getElementById(contentId);

        // Toggle ARIA
        const currentState = e.currentTarget.getAttribute('aria-expanded');
        const nextState = currentState === 'false' ? 'true' : 'false';
        e.currentTarget.setAttribute('aria-expanded', nextState);

        // Toggle arrow
        const $arrow = e.currentTarget.getElementsByTagName('img')[0];
        $arrow.classList.toggle('rotate-180');

        // Toggle content visibility
        contentElement.classList.toggle('hidden');
        if (nextState === 'true') {
          contentElement.focus();
        }
      },
      false,
    );
  });
});
